import React, { useState } from "react";
import { Routes, Route } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { ToastContext } from "./toastContext"
import RouteList from "./routeList"
import { Flex } from "./components/atoms";
import Notifications from './components/molecules/toast';
import "@material-tailwind/react/tailwind.css";
import "./index.css"

function App() {
  const [toastShowed, setToastShowed] = useState(false);

  return (
    <Flex className={["tw-h-full"]}>
      <ToastContext.Provider value={{ toastShowed, setToastShowed }} >
        <Routes>
          {RouteList && RouteList.map(({ ...rest }, idx) =>

            <Route
              key={idx}
              {...rest}
            />

          )});
        </Routes>
        <Notifications isDesktop={!isMobile} />
      </ToastContext.Provider>
    </Flex>
  )
}

export default App;
