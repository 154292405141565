import React from "react";
import classNames from "classnames";

const Image = ({ width, height, src, title, alt, onClick, className = [] }) => {
  const imgClass = classNames("tw-object-cover", ...className);
  return (
    <img
      width={width}
      height={height}
      src={src}
      title={title}
      alt={alt}
      className={imgClass}
      onClick={onClick}
    />
  );
};

export default Image;
