import React from "react";
import Modal from "@material-tailwind/react/Modal";

export default function ModalView(props) {
  const { children, showModal, setShowModal } = props;

  return (
    <Modal size="sm" active={showModal} toggler={() => setShowModal(false)}>
      {children}
    </Modal>
  );
}
