import React from "react";
import { Flex, Paragraph } from "../../components/atoms";
import { actualPrices, valuta } from "../../data/texts/prices";

const PricesContainer = () => {
  return (
    <Flex className={["tw-w-1/3 md:tw-text-right"]}>
      <Paragraph color="white">
        {actualPrices.euNyiras.concat(valuta.value)}
      </Paragraph>
      <Paragraph color="white">
        {actualPrices.bFSz.concat(valuta.value)}
      </Paragraph>
      <Paragraph color="white">
        {actualPrices.teljesKezeles.concat(valuta.value)}
      </Paragraph>
    </Flex>
  );
};

export default PricesContainer;
