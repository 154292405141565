import React from "react";
import classNames from "classnames";

const List = (props) => {
  const {
    direction = "col",
    className = [],
    options = [],
    onClick,
    onScroll,
  } = props;
  const flexClass = classNames(
    "tw-relative",
    "tw-flex",
    `tw-flex-${direction}`,
    "tw-flex-shrink-0",
    ...className
  );

  return (
    <div onScroll={onScroll} className={flexClass} onClick={onClick}>
      <ul className="tw-list-disc tw-p-2">
        {options && options.map((option) => <li className="tw-p-2">{option}</li>)}
      </ul>
    </div>
  );
};

export default List;
