import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContext } from "../../toastContext";
import { Flex, Img, Paragraph } from "../../components/atoms";
import Header from "../../components/molecules/header";
import Footer from "../../components/molecules/footer";
import Slideshow from "../../components/molecules/slideShow";
import { texts, intorduction } from "../../data/texts";

const LandingPage = (props) => {
  const navigate = useNavigate();
  const toastContext = useContext(ToastContext);

  useEffect(() => {
    if (!toastContext.toastShowed) {
      toast.info(texts.udvozoljukWeblapunkon, {
        icon: false,
        delay: 2000,
      });

      toastContext.setToastShowed(true);
    }
  });

  return (
    <Flex className={["tw-w-full tw-bg-pink tw-mt-12 md:tw-pt-12"]}>
      <Header />
      <Flex
        className={[
          "md:tw-max-w-lg md:tw-m-auto md:tw-flex-row md:tw-justify-between md:tw-items-center",
        ]}
      >
        <Img
          src={"/img/logo_fekete.png"}
          title="mainHeaderLogo"
          alt="Logo"
          className={[
            "tw-w-full tw-p-4 tw-mt-4 md:tw-w-1/3 md:tw-h-fit md:tw-p-0",
          ]}
          onClick={() => navigate("/")}
        />
        <Slideshow />
      </Flex>
      <Flex className={["tw-w-full tw-p-4 md:tw-max-w-lg md:tw-m-auto"]}>
        <Paragraph color="black" size="4xl" className={["tw-font-monotypec"]}>
          {intorduction.landingIntroTitle}
        </Paragraph>
        <Paragraph color="black" size="xl" className={["tw-font-monotypec"]}>
          {intorduction.landingIntroText}
        </Paragraph>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default LandingPage;
