import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

const CoreLink = (props) => {
  const {
    children,
    className = [],
    to,
    external = false,
    activeStyle = [],
  } = props;
  const linkClass = classNames(...className);
  const activeLinkClass = classNames(...className, ...activeStyle);



  if (external) {
    return (
      <a className={linkClass} href={to}>
        {children}
      </a>
    );
  } else {
    return (
      <NavLink
        className={({ isActive }) => (isActive ? activeLinkClass : linkClass)}
        to={to}
      >
        {children}
      </NavLink>
    );
  }
};

export default CoreLink;
