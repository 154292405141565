import { texts } from "../../data/texts";

export const tabsNumbers = {
    first: 1,
    second: 2,
    third: 3,
};

export const tabsProps = {
    tab_1: { title: texts.kedvezmeny },
    tab_2: {
        title: texts.egyebTudnivalok,
        subTitle1: texts.euNyirasTart,
        subTitle2: texts.teljesKozmKezeles,
    },
    tab_3: { title: texts.vegsoAr },


};


