import React from "react";
import classNames from "classnames";

/* 
Color Scheme
tw-from-red-dark
tw-to-red
tw-from-green-dark
tw-to-green
tw-from-darkBlue-dark
tw-to-darkBlue
tw-from-blue-dark
tw-to-blue
*/

const Button = (props) => {
  const {
    type = "button",
    className = [],
    onClick = () => {},
    children,
    size = "md",
    disabled = false,
    textColor = "white",
    rounded = "2xl",
    secondary = false,
    tertiary = false,
    quaternary = false,
    quinternary = false,
    hexternary = false,
  } = props;

  const styleSizes = {
    md: ["tw-py-4", "tw-px-5"],
    sm: ["tw-py-1", "tw-px-5"],
    xs: ["tw-py-1", "tw-px-2"],
    base: ["tw-py-3"], //These buttons have fix 45% width. No px needed.
  };
  const color =
    (secondary && "buttonBrown") ||
    (tertiary && "pink") ||
    (quaternary && "buttonBlue") ||
    (quinternary && "black") ||
    (hexternary && "buttonWhite") ||
    "green";

  const btnClass = classNames(
    "tw-inline-block",
    "tw-flex-shrink-0",
    "tw-text-center",
    "tw-align-middle",
    "tw-select-none",
    `tw-rounded-${rounded}`,
    `tw-text-${textColor}`,
    "tw-bg-gradient-to-t",
    { [`tw-from-${color}-dark`]: !disabled },
    { [`tw-to-${color}`]: !disabled },
    { "tw-bg-grey": disabled },
    ...styleSizes[size],
    ...className
  );
  return (
    <button
      type={type}
      className={btnClass}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </button>
  );
};

export default Button;
