import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { Flex } from "../../atoms";
import { google } from "../../../data/utils";
import { texts } from "../../../data/texts";

const GoogleMap = (props) => {
  const style = {
    width: "100%",
    height: "100%",
    position: "relative",
  };
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  return (
    <Flex className={["tw-h-96 tw-w-90 tw-p-4 tw-m-auto md:tw-px-0"]}>
      <Map
        containerStyle={containerStyle}
        google={props.google}
        zoom={16}
        style={style}
        center={{ lat: 47.54668530054872, lng: 19.127735782683438 }}
        initialCenter={{ lat: 47.54668530054872, lng: 19.127735782683438 }}
      >
        <Marker
          title={texts.miraluxGoogleName}
          name={texts.miraluxGoogleName}
          position={{ lat: 47.54668530054872, lng: 19.127735782683438 }}
        />
      </Map>
    </Flex>
  );
};

export default GoogleApiWrapper({
  apiKey: google.googleKey,
})(GoogleMap);
