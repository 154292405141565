import React from "react";
import { Flex, Img, Paragraph, ModalView } from "../../atoms";
import Menu from "./menu";

//This header is used on mobile.
const MobileHeader = (props) => {
  const { headerText, navigate, toggle, setToggle, toggleMenu } = props;
  return (
    <>
      {toggle ? (
        <ModalView showModal={true} setShowModal={setToggle}>
          <Menu navigate={navigate} toggle={toggle} toggleMenu={toggleMenu} />
        </ModalView>
      ) : (
        <Flex
          direction="row"
          className={[
            "tw-z-50 tw-px-2 tw-py-4 tw-justify-between tw-bg-brown tw-top-0 tw-left-0 tw-right-0",
          ]}
          position="fixed"
        >
          <Img
            src={"/img/logo_feher.png"}
            title="mainHeaderLogo"
            alt="Logo"
            className={["tw-w-16 tw-absolute"]}
            onClick={() => navigate("/")}
          />
          <Paragraph size="xl" className={["tw-my-1 tw-text-black"]}>
            {headerText}
          </Paragraph>
          <Img
            src={"/img/btn_Hamburger.png"}
            title="mainHeaderLogo"
            alt="Logo"
            className={["tw-h-4 tw-m-2"]}
            onClick={toggleMenu}
          />
        </Flex>
      )}
    </>
  );
};

export default MobileHeader;
