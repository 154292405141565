

import LandingComponent from "./containers/landingPage";
import AboutUs from "./containers/aboutUs"
import ContactUs from "./containers/contactUs"
import Prices from "./containers/prices"

const RouteList = [
  {
    path: "/",
    element: <LandingComponent />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/contactUs",
    element: <ContactUs />,
  },
  {
    path: "/prices",
    element: <Prices />,
  },
];

export default RouteList;
