import React from "react";
import classNames from "classnames";

const Paragraph = (props) => {
  const {
    size = "base",
    className = [],
    muted,
    children,
    color = "white",
  } = props;
  const paragraphClass = classNames(
    { [`tw-text-${color}`]: !muted },
    { "tw-text-muted": muted },
    { "tw-text-base": !size },
    `tw-text-${size}`,
    "tw-mb-4",
    "tw-leading-tight",
    ...className
  );

  return <p className={paragraphClass}>{children}</p>;
};

export default Paragraph;
