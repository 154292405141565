import React from "react";
import classNames from "classnames";
import Tab from "./tab";

const TabComponent = (props) => {
  const {
    direction = "row",
    className = [],
    children,
    onClick,
    tabs = [],
    bgGolor = "pink",
  } = props;
  const flexClass = classNames(
    "tw-relative",
    "tw-top-1",
    "tw-flex",
    `tw-flex-${direction}`,
    `tw-bg-${bgGolor}`,
    "tw-flex-shrink-0",
    "justify-between",
    "tw-p-2 md:tw-px-8",
    "tw-rounded-t-xl",
    ...className
  );

  return (
    <div className={"tw-mx-2 tw-pb-2 md:tw-max-w-lg md:tw-w-full md:tw-m-auto md:tw-p-4"}>
      <div className={flexClass} onClick={onClick}>
        {tabs && tabs.map((tab) => <Tab onClick={tab.setActive} {...tab} />)}
      </div>
      {children}
    </div>
  );
};

export default TabComponent;
