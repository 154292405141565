import React from "react";
import classNames from "classnames";

const Tab = (props) => {
  const {
    className = [],
    defaultBackground = "pink",
    bgGolor = "brown",
    textlolor = "white",
    onClick,
    text,
    active = false,
  } = props;

  const background = active ? bgGolor : defaultBackground;

  const flexClass = classNames(
    "tw-relative",
    `tw-text-${textlolor}`,
    `tw-bg-${background}`,
    { "tw-border-b-2": !active },
    { [`tw-border-${bgGolor}`]: !active },
    "tw-text-center",
    "tw-p-2",
    "tw-rounded-xl",
    "tw-w-24",
    ...className
  );

  return (
    <div className={flexClass} onClick={onClick}>
      {text}
    </div>
  );
};

export default Tab;
