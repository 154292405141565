export const actualPrices = {
    euNyiras: "2800",
    bFSz: "3800-4800",
    teljesKezeles: "8500-10500",
}

export const pricesText = {
    euNyiras: "Egészségügyi nyírás",
    bFSz: "Bontas, Fürdetés, Szárítás:",
    allapotFuggo: "állapottól és szőrtől függően",
    teljesKezeles: "Teljeskörű kozmetikai kezelés:",
}


export const sale = {
    kedvezmeny1: "Harmadik alkalom után 7% kedvezmény",
    kedvezmeny2: "Ötödik alkalom után 12% kedvezmény",
}

export const valuta = {
    value: " Ft",
}
