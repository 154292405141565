import React from "react";
import { Flex, Img, CloseButton, Link} from "../../atoms";
import MenuItems from "../../molecules/header/menuItems";

function OpenHeaderMenu(props) {
  const { navigate, toggleMenu } = props;
  return (
      <Flex
        className={[
          "slideFromTop tw-h-full tw-w-full tw-items-center tw-bg-black tw-z-50",
        ]}
      >
        <CloseButton className={["tw-p-4 tw-self-end"]} onClick={toggleMenu} />
        <Link to="/">
          <Img
            className={["tw-p-4"]}
            src={"/img/logo_feher.png"}
            title="miralux-logo"
            alt="miralux-logo"
          />
        </Link>
        <MenuItems navigate={navigate} />
      </Flex>
  );
}

export default OpenHeaderMenu;
