import React from "react";
import { Flex, Img, Link } from "../../atoms";
import { texts } from "../../../data/texts";

//This header is used on desktop.
const DesktopHeader = (props) => {
  const { navigate } = props;
  const navlinkClass = [
    "tw-p-2 tw-mx-8 tw-font-monotypec tw-text-2xl tw-text-white tw-font-black hover:tw-text-black",
  ];
  const activeLinkStyle = ["tw-text-black"];

  return (
    <Flex
      direction="row"
      className={[
        "tw-z-50 tw-px-1 tw-py-1 tw-bg-brown tw-justify-between tw-top-0 tw-left-0 tw-right-0 tw-text-lg",
      ]}
      position="fixed"
    >
      <Img
        src={"/img/logo_feher.png"}
        title="mainHeaderLogo"
        alt="Logo"
        className={["tw-w-32"]}
        onClick={() => navigate("/")}
      />
      <Flex direction="row" className={["tw-grow"]}>
        <Link to={"/"} className={navlinkClass} activeStyle={activeLinkStyle}>
          {texts.home}
        </Link>
        <Link
          to={"/prices"}
          className={navlinkClass}
          activeStyle={activeLinkStyle}
        >
          {texts.prices}
        </Link>
        <Link
          to={"/contactUs"}
          className={navlinkClass}
          activeStyle={activeLinkStyle}
        >
          {texts.contactUs}
        </Link>
        {/* <Flex onClick={() => navigate("/aboutus")} className={navlinkClass}>
        {texts.aboutus}
            </Flex> */}
      </Flex>
    </Flex>
  );
};

export default DesktopHeader;
