import React from "react";
import classNames from "classnames";

const Flex = (props) => {
  const {
    direction = "col",
    className = [],
    children,
    onClick,
    onScroll,
    hidden,
    position = "relative",
  } = props;
  const flexClass = classNames(
    `tw-${position}`,
    "tw-flex",
    `tw-flex-${direction}`,
    "tw-flex-shrink-0",
    { "tw-hidden": hidden },
    ...className
  );

  return (
    <div onScroll={onScroll} className={flexClass} onClick={onClick}>
      {children}
    </div>
  );
};

export default Flex;
