import React from "react";
import classNames from "classnames";

/* 
Color Scheme
tw-from-red-dark
tw-to-red
tw-from-green-dark
tw-to-green
tw-from-darkBlue-dark
tw-to-darkBlue
tw-from-blue-dark
tw-to-blue
*/

const CloseButton = (props) => {
  const { className = [], onClick = () => {} } = props;

  const btnClass = classNames(
    "tw-inline-block",
    "tw-flex-shrink-0",
    "tw-text-center",
    "tw-align-middle",
    "tw-select-none",
    "tw-rounded-2xl",
    "tw-text-white",
    "tw-text-xl",
    `tw-bg-black`,
    ...className
  );
  return (
    <button type="button" className={btnClass} onClick={onClick}>
      x
    </button>
  );
};

export default CloseButton;
