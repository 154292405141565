import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Img, Link } from "../../atoms";
import { footerLinks } from "../../../data/footerLinks";
import { texts } from "../../../data/texts";
import { facebook } from "../../../data/utils";

const Footer = (props) => {
  const navigate = useNavigate();

  return (
    <Flex className={[`tw-py-4 tw-justify-between tw-bg-grey tw-text-xxs`]}>
      <Flex
        direction="row"
        className={["tw-w-3/4 tw-p-2 md:tw-max-w-lg md:tw-m-auto"]}
      >
        <Flex className={["tw-w-3/4 tw-p-2"]}>
          <Flex className={["tw-p-2"]}>
            <Flex
              className={["tw-py-1 tw-font-black"]}
            >{`${texts.linksText}:`}</Flex>
            {footerLinks() &&
              footerLinks().length > 0 &&
              footerLinks().map((link) => (
                <Flex
                  className={["tw-py-1 tw-underline"]}
                  onClick={() => navigate(link?.url || "/")}
                >
                  {link.text}
                </Flex>
              ))}
          </Flex>
          <Flex className={["tw-p-2 tw-justify-between tw-text-xxs"]}>
            <Flex className={["tw-text-black tw-font-black tw-py-1"]}>
              {texts.design}
            </Flex>
            <Flex className={["tw-text-black"]}>{texts.stbFruzsina}</Flex>
            <Flex className={["tw-text-black"]}>
              {texts.email} {texts.stbFruzsinaEmail}
            </Flex>
            <Flex className={["tw-text-black"]}>
              {texts.telefon} {texts.stbFruzsinaPhone}
            </Flex>
          </Flex>
        </Flex>
        <Flex className={["tw-w-1/4 tw-p-2"]}>
          <Link to={facebook.miraLuxUrl} external={true} className={["tw-p-4"]}>
            <Img
              src={"/img/facebook.png"}
              title="MiraLux-Facebook"
              alt="Kutyakozmetika-facebook-img"
              className={["tw-w-12"]}
            />
          </Link>
          <Link to={facebook.miraLuxUrl} external={true} className={["tw-p-4"]}>
            <Img
              src={"/img/instagram.png"}
              title="MiraLux-Facebook"
              alt="Kutyakozmetika-facebook-img"
              className={["tw-w-12"]}
            />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
