import React from "react";
import GoogleApiWrapper from "../../components/molecules/googleMap";
import { toast } from "react-toastify";
import { Flex, Button, Heading } from "../../components/atoms";
import Header from "../../components/molecules/header";
import Footer from "../../components/molecules/footer";
import { texts } from "../../data/texts";
import { google } from "../../data/utils";

const ContactUs = (props) => {
  const copyToClipBoard = (text) => {
    if (text) {
      const textToCopy = text?.replaceAll("-", "");
      navigator.clipboard.writeText(textToCopy);
    }
    toast.success(texts.sikeresMasolas, {
      icon: true,
    });
  };

  const openGoogleMapsTab = () => {
    window.open(google.googleHref);
  };

  return (
    <Flex className={["tw-w-full tw-bg-pink tw-mt-16"]}>
      <Header />
      <Flex className={["tw-w-full tw-p-2 md:tw-max-w-lg md:tw-m-auto"]}>
        <Heading
          size="6xl"
          className={["tw-self-center tw-p-2 tw-font-distantStroke"]}
        >
          {texts.contactUs}{" "}
        </Heading>
        {/* Phone number */}
        <Flex className={["tw-justify-center tw-pr-2"]}>{texts.telefon}</Flex>
        <Flex
          direction="row"
          className={["tw-w-full tw-justify-between tw-mb-4"]}
        >
          <Flex className={["tw-justify-center"]}>{texts.telefonSzam}</Flex>
          <Flex className={["tw-justify-center"]}>
            <Button
              textColor="black"
              secondary
              onClick={() => copyToClipBoard(texts.telefonSzam)}
            >
              {texts.copy}{" "}
            </Button>
          </Flex>
        </Flex>
        {/* Email */}
        <Flex className={["tw-justify-center tw-pr-2"]}>{texts.email}</Flex>
        <Flex
          direction="row"
          className={["tw-w-full tw-justify-between tw-mb-4"]}
        >
          <Flex className={["tw-justify-center"]}>{texts.emailAddress}</Flex>
          <Flex className={["tw-justify-center"]}>
            <Button
              textColor="black"
              secondary
              onClick={() => copyToClipBoard(texts.emailAddress)}
            >
              {texts.copy}{" "}
            </Button>
          </Flex>
        </Flex>
        {/* Address */}
        <Flex className={["tw-justify-center tw-pr-2"]}>{texts.cim}</Flex>
        <Flex
          direction="row"
          className={["tw-w-ful tw-justify-between tw-mb-4"]}
        >
          <Flex className={["tw-justify-center"]}>{texts.address}</Flex>
          <Flex className={["tw-justify-center"]}>
            <Button
              textColor="black"
              secondary
              onClick={() => copyToClipBoard(texts.address)}
            >
              {texts.copy}{" "}
            </Button>
          </Flex>
        </Flex>
        {/* Google Maps */}
        <Flex className={["tw-justify-center tw-pb-2"]}>
          {texts.googleMaps}
        </Flex>
        <Flex onClick={openGoogleMapsTab}>
          <Button
            size="base"
            textColor="black"
            quaternary
            className={["tw-w-full"]}
          >
            {texts.openGoogleMaps}
          </Button>
        </Flex>

        <GoogleApiWrapper />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default ContactUs;
