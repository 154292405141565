import React from "react";
import classNames from "classnames";

const Heading = (props) => {
  const { className = [], children, color = "white", size = "3xl", marginBottom = 6 } = props;
  const headingClass = classNames(
    `tw-text-${color}`,
    `tw-text-${size}`,
    `tw-mb-${marginBottom}`,
    ...className
  );

  return <h1 className={headingClass}>{children}</h1>;
};

export default Heading;
