import React from "react";
import classNames from "classnames";

const SubHeader = (props) => {
  const { className = [], children, color = "white", size = "xl", marginBottom = 2 } = props;
  const headingClass = classNames(
    `tw-text-${color}`,
    `tw-text-${size}`,
    `tw-mb-${marginBottom}`,
    ...className
  );

  return <h2 className={headingClass}>{children}</h2>;
};

export default SubHeader;
