import React from "react";
import { tabsNumbers } from "./constants";
import { Flex, Heading, List, SubHeader } from "../../components/atoms";
import { sale } from "../../data/texts/prices";
import { egyebTudnivalok, vegsoAr } from "../../data/texts";

const TabsContainer = (props) => {
  const { active } = props;
  const tabClassName = "tw-bg-pink tw-rounded-b-xl";
  return (
    <Flex className={[""]}>
      {/* First tab */}
      <Flex hidden={active !== tabsNumbers.first} className={[tabClassName]}>
        <Heading className={["tw-mx-auto tw-p-4 "]} marginBottom="0">
          {props.tab_1.title}
        </Heading>
        <Flex className={["tw-w-full tw-p-4 tw-mb-4 md:tw-px-8"]}>
          <List options={Object.values(sale)} />
        </Flex>
      </Flex>

      {/* Second tab */}
      <Flex hidden={active !== tabsNumbers.second} className={[tabClassName]}>
        <Heading className={["tw-mx-auto tw-p-4"]} marginBottom="0">
          {props.tab_2.title}
        </Heading>
        <SubHeader className={["tw-mx-auto tw-px-4 md:tw-px-8"]} marginBottom="0">
          {props.tab_2.subTitle1}
        </SubHeader>
        <Flex className={["tw-w-full tw-p-4 tw-mb-4 md:tw-px-8"]}>
          <List options={Object.values(egyebTudnivalok)} />
        </Flex>
        <SubHeader className={["tw-mx-auto tw-px-4 md:tw-px-8"]} marginBottom="0">
          {props.tab_2.subTitle2}
        </SubHeader>
      </Flex>

      {/* Third tab */}
      <Flex hidden={active !== tabsNumbers.third} className={[tabClassName]}>
        <Heading className={["tw-mx-auto tw-p-4 md:tw-px-8"]} marginBottom="0">
          {props.tab_3.title}
        </Heading>
        <Flex className={["tw-w-full tw-p-4 tw-mb-4 md:tw-px-8"]}>
          <List options={Object.values(vegsoAr)} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TabsContainer;
