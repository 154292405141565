import React from "react";
import { Flex } from "../../atoms";
import { texts } from "../../../data/texts";

function MenuItems(props) {
  const { navigate } = props;
  const navlinkClass = [
    "tw-p-1 tw-m-1 tw-text-2xl tw-text-white hover:tw-text-brown",
  ];
  return (
    <Flex className={["tw-px-2 tw-py-4 tw-items-center"]}>
      <Flex onClick={() => navigate("/")} className={navlinkClass}>
        {texts.home}
      </Flex>

      {/* <Flex onClick={() => navigate("/aboutus")} className={navlinkClass}>
        {texts.aboutus}
      </Flex> */}

      <Flex onClick={() => navigate("/prices")} className={navlinkClass}>
        {texts.prices}
      </Flex>

      <Flex onClick={() => navigate("/contactUs")} className={navlinkClass}>
        {texts.contactUs}
      </Flex>
    </Flex>
  );
}

export default MenuItems;
