import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import MobileHeader from "./mobileHeader";
import DesktopHeader from "./desktopHeader";

//This header needs to be set for every pages where it is used.
const Header = (props) => {
  const { headerText } = props;
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const toggleMenu = () => {
    setToggle(!toggle);
  };

  return (
    <>
      {isMobile ? (
        <MobileHeader
          headerText={headerText}
          navigate={navigate}
          toggle={toggle}
          setToggle={setToggle}
          toggleMenu={toggleMenu}
        />
      ) : (
        <DesktopHeader navigate={navigate} />
      )}
    </>
  );
};

export default Header;
