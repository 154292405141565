import React from "react";
import { Flex, Paragraph } from "../../components/atoms";
import { pricesText } from "../../data/texts/prices";

const TextsContainer = () => {
  return (
    <Flex className={["tw-w-2/3"]}>
      <Paragraph color="white">
        {pricesText.euNyiras}
      </Paragraph>
      <Paragraph color="white">
        {pricesText.bFSz}
      </Paragraph>
      <Paragraph color="white">
        {pricesText.teljesKezeles}
      </Paragraph>
    </Flex>
  );
};

export default TextsContainer;
