export const texts = {
    //#region Linkek
    home: "Főoldal",
    aboutus: "Rólunk",
    prices: "Áraink",
    contactUs: "Kapcsolat",
    //#endregion 

    design: "Dizájn:",
    linksText: "Linkek",
    copy: "Másolás",
    akciok: "Akciók",
    reszletek: "Részletek",
    vegosszeg: "Végösszeg",
    telefon: "Telefon:",
    telefonSzam: "+3620-218-07-94",
    email: "Email:",
    emailAddress: "ardaiadrienn0077@gmail.com",
    cim: "Cím:",
    address: "1154 Budapest, Szerencs utca 172",
    stbFruzsina: "Stubenvoll Fruzsina",
    stbFruzsinaEmail: "stubenvoll.fruzsina@gmail.com",
    stbFruzsinaPhone: "+36-70-314-5607",
    facebook: "Facebook:",
    udvozoljukWeblapunkon: "Üdvözöljük Weboldalunkon!",
    kedvezmeny: "Kedvezmények",
    egyebTudnivalok: "Egyéb tudnivalók",
    vegsoAr: "Mitől függ a végső ár?",
    euNyirasTart: "Az egészségügyi nyírás tartalmazza az alábbiakat:",
    sikeresMasolas: "A szöveget sikeresen kimásoltuk a vágólapra!",
    googleMaps: "Google maps:",
    openGoogleMaps: "Google maps megnyitása a fenti címre",
    miraluxGoogleName: "Miralux kutyafodrászat",

    kozmetikaLimit: `Kutyafodrászatom méretei illetve személyes testi adottságaim miatt 
    maximum 10kg súlyig tudom vállalni a kutyusok szépítését és ápolását.`,

    teljesKozmKezeles: `A teljes körű kozmetikai kezelés 
    tartalmazza az egészségügyi nyírást, a bontást, fürdetést, szárítást illetve a nyírást és a fazon kialakítását.`,
}

export const intorduction = {
    landingIntroTitle: `Kedves leendő Vendégeim!`,
    landingIntroText: `Ezúton szeretném veletek megosztani, hogy immár végzett kutyakozmetikus vagyok, 
    így végre foglalkozhatok azokkal, akiket nagyon szeretek, azaz kellő odaadással, türelemmel, 
    precizitással ápolhatom, szépíthetem, csinosíthatom ismerőseim kistestű kutyusait.
    Sok szeretettel várom leendő kutyavendégeimet és gazdijaikat itthoni kutyafodrászatomba! 
    Ha szeretnétek, hogy kiskutyátok szép és ápolt legyen, keressetek bátran!`
}

export const egyebTudnivalok = {
    szemSzorK: "Szem előtti szőr kivágása.",
    fulszorK: "Fülszőr kiszedése.",
    karomV: "Karomvágás.",
    tappancsSzK: "Tappancs szőr kivágása.",
    vegbelSzorL: "Végbélnyílás körüli szőr levágása.",
}

export const vegsoAr = {
    mennyireCsomos: "A kutyusnak mennyire csomós a szőre és ennek kibontása mennyi időt vesz igénybe.",
    gepVagyOllo: "A gazdi géppel vagy ollóval szeretné-e hogy dolgozzak.",
    balzsamotHaszn: " A gazdi szeretné-e hogy balzsamot is használjak a kiskedvencén, ezzel még puhábbá varázsolva szőrét.",
    milyenHosszuSz: "A kutyának milyen hosszú a szőre, mivel az több sampont és balzsamot igényel."
}

