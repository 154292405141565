import { Flex } from "../../components/atoms";
import Header from "../../components/molecules/header";

const AboutUs = () => {
  return (
    <Flex>
      <Header />
    </Flex>
  );
};

export default AboutUs;
