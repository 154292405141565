import React, { useState } from "react";
import { tabsProps, tabsNumbers } from "./constants";
import TabsContainer from "./tabsContainer";
import TextsContainer from "./textsConatiner";
import PricesContainer from "./pricesContainer";
import { Flex, Paragraph, Heading, TabComponent } from "../../components/atoms";
import Header from "../../components/molecules/header";
import Footer from "../../components/molecules/footer";
import { texts } from "../../data/texts";

const Prices = () => {
  const [active, setActive] = useState(1);
  const tabs = [
    {
      text: texts.akciok,
      setActive: () => setActive(tabsNumbers.first),
      active: tabsNumbers.first === active,
    },
    {
      text: texts.reszletek,
      setActive: () => setActive(tabsNumbers.second),
      active: tabsNumbers.second === active,
    },
    {
      text: texts.vegosszeg,
      setActive: () => setActive(tabsNumbers.third),
      active: tabsNumbers.third === active,
    },
  ];

  return (
    <Flex
      className={[
        "tw-h-full tw-mt-16 tw-overflow-auto tw-bg-prices-background-img tw-bg-cover tw-bg-no-repeat tw-bg-center md:tw-h-auto md:tw-overflow-hidden",
      ]}
    >
      <Header />
      <Heading
        marginBottom="0"
        size="6xl"
        className={[
          "tw-mx-auto tw-p-4 tw-font-distantStroke md:tw-max-w-lg md:tw-m-auto",
        ]}
      >
        {texts.prices}
      </Heading>
      <Flex
        direction="row"
        className={["tw-w-full tw-p-4 tw-mb-4 md:tw-max-w-lg md:tw-m-auto"]}
      >
        <TextsContainer />
        <PricesContainer />
      </Flex>
      <Paragraph
        color="white"
        className={[" tw-p-4 tw-mb-16 md:tw-max-w-lg md:tw-m-auto"]}
      >
        {texts.kozmetikaLimit}
      </Paragraph>
      <TabComponent
        tabs={tabs}
        children={<TabsContainer active={active} {...tabsProps} />}
      />
      <Footer />
    </Flex>
  );
};

export default Prices;
